.App {
  text-align: center;
}

html, body, #root, .App {
  height: 100%;
  width: 100%;
}

.div2 {
  height: auto;
  width: 100%;
  overflow-x: hidden;
}

.div1 {
  height: 100%;
  width: 100%;
  background: white;
  margin-top: 2%;
}


