.contact-div {
    width: 100%;
    background: #37A7FF;
    padding: 2% 0;
}

.contact-title {
    padding-top: 5vh;
    font-size: 400%;
    font-weight: bold;
    color: white;
    text-align: center;
}

.contact-line {
    font-size: 150%;
    padding: 5vh 0vw 5vh 0vw;
    color: white;
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .contact-title {
        padding-top: 5%;
        font-size: 350%;
    }

    .contact-line {
        font-size: 120%;
    }

    .contact-logo {
        height: 2rem !important;
        width: auto;
    }

    .contact-info {
        margin: auto 30vw 6vh 30vw !important;
    }
}

.copyright {
    margin-bottom: 0;
    padding: 4vh auto 0vh auto;
    color: white
}

.contact-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
    margin: auto 40vw 6vh 40vw;
}

.contact-logo {
    height: 32px;
    width: auto;
}

.github {
    margin-left: -0.75vw;
    padding-right: 1.25vw;
}

.other {
    padding-right: 1.5vw;
}