.about-div {
    width: 100%;
    background: #37A7FF;
    height: 100%;
    padding-bottom: 10vh;
}

.about-title {
    margin-top: 0;
    padding-top: 10vh;
    font-size: 400%;
    font-weight: bold;
    color: white;
}

.about-pic {
    display: flex;
    justify-content: space-around;
    background: white;
    border-radius: 50%;
    border: 2px solid #003076;
    width: 60%;
    margin: 24px;
}

.about-content-container {
    margin: 2% 5%;
    padding-top: 3vh;
    padding-left: 2vw;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background: white;
    display: flex;
    flex-direction: row;
    width: 95%;
}

.about-content-image {
    width: 30%
}

.about-content-description {
    text-align: left;
    font-size: 100%;
    width: 65%;
    padding: 0 5% 4% 0;
}

.subheading {
    margin-bottom: 1%;
}

.text-blue {
    color: #37A7FF;
}

.text-blue-i {
    color: #37A7FF;
    font-style: italic;
}

.about-content:last-child {
    display: flex;
}

.subheading-skill {
    font-weight: bold;
    font-size: 110%;
}

.chips {
    display: inline-block;
    padding: 4px 15px;
    border-radius: 15px;
    margin: 1% 1% auto 0;
    color: white;
}

.orange {
    background-color: orange;
}

.green {
    background-color: springgreen;
}

.chips:first-child {
    margin-left: 0;
}

@media only screen and (max-width: 1050px) {
    .about-title {
        font-size: 300%;
        margin: 0vh 1vw 5vh 1vw;
    }

    .about-content-container {
        flex-direction: column;
        justify-content: space-around;
    }

    .about-content-description {
        width: 90%;
        padding: 2% 8% 8% 8%;
        margin-right: 8%;
    }

    .about-content-image {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 2%;
    }

    .about-pic {
        width: 35%;
        text-align: center;
    }

    .about-content-description {
        text-align: left;
        font-size: 120%;
    }
}