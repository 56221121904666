.projects-title {
    padding-top: 10vh;
    padding-bottom: 5vh;
    font-size: 400%;
    font-weight: bold;
    color: #003076;
}

.projects-div {
    background-color: #dbdbdb;
    padding-bottom: 10vh;
}

.project-card {
    width: 30%;
    border-radius: 25px;
    padding: auto 3%
}

.project-card:last-child{
    padding-bottom: 0;
}

.cards-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: auto 5%;
}

.wip-row {
    display: flex;
    justify-content: space-between;
}

.wip-row > tiny {
    margin-bottom: 0;
    color: blue;
    padding-right: 2vw;
}

@media only screen and (max-width: 1050px) {
    .projects-title {
        font-size: 300%;
        margin: 5vh 1vw 2vh 1vw;
        padding-top: 5vh;
        padding-bottom: 1vh;
    }

    .cards-container {
        flex-direction: column;
    }

    .project-card {
        width: 60%;
        align-self: center;
        margin-bottom: 2vh ;
    }
}

.instafix {
    height: 19vh;
    padding-top: 2vh;
}

.quickpass {
    width: 35vh;
    padding-top: 4vh;
}

.instant {
    height: 19vh;
    padding-top: 2vh;
}

.gitlink {
    text-decoration: none;
}