.experience-div {
    display: flex;
    width: 45%;
    flex-direction: column;
    padding-bottom: 5vh;
}
.experience-title {
    padding-top: 10vh;
    padding-bottom: 5vh;
    font-size: 400%;
    font-weight: bold;
    color: #003076;
}

@media only screen and (max-width: 1050px) {
    .experience-title {
        font-size: 300%;
        margin: 5vh 1vw 2vh 1vw;
        padding-top: 5vh;
        padding-bottom: 0;
    }

    .experience-grid {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .job-card {
        margin: 2vh 2%;
        width: 65%;
        padding: 4vh 6vw;
    }

    .container {
        flex-direction: column !important;
        margin: auto;
    }

    .experience-div {
        width: 90% !important;
        align-self: center;
    }
}


.container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    vertical-align: top;
}



.timeline-wrapper {
    position: relative;
    padding: 5% 0;
}

.timeline-wrapper:last-child:before {
    height: 0;
}

.timeline-wrapper:before {
    content: "";
    background: #474559;
    width: 3px;
    height: 100%;
    position: absolute;
    left: 38px;
}

.timeline-yr {
    background: #474559;
    border-radius: 100%;
    position: absolute;
    width: 75px;
    height: 75px;
    line-height: 75px;
    text-align: center;
}

.timeline-yr span {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    display: block;
}

.timeline-info {
    display: inline-block;
    vertical-align: top;
    width: 80%;
    padding-left: 5%;
    margin-left: 6em;
    text-align: left;
}

.timeline-info small {
    color: #474559;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;

}


.timeline-info p {
    text-align: left;
    padding-right: 3vh;
}

.timeline-chips {
    display: inline-block;
    padding: 4px 15px;
    border-radius: 15px;
    margin: 1% 1% auto 0;
    color:white;
    background: #37A7FF;;
}

tiny {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 100px;
}

.tool-container {
    padding-top: 5px;
}

.experience-div h2{
    padding-top: 3vh;
}