.header {
    position: fixed;
    width: 100%;
    background: white;
    padding: 0.5vh 0 0 0;
    z-index: 1;

}

.scroll {
    box-shadow: 0 10px 2px 0 grey;
    -webkit-box-shadow: 0 2px 4px 0 grey;
    -moz-box-shadow: 0 2px 4px 0 grey;
}

.nav-items {
    position: relative;
    display: inline-block;
    margin: 2vh 0 2vh 0;
    padding: 0 1%;
    float: right;
}

.nav-items > .about {
    margin-left: 0;
}

.nav-item {
    padding: 0.5vh 2vw;
    display: inline;
    margin: auto 2vw;
    color: #333;
    overflow: hidden;
    font-size: 1.25rem;
}

.nav-item:hover {
    background-color: #003076;
    color: white;
    border-radius: 15px;
    overflow: hidden;

}

.nav-item > .active {
    border-bottom: 2px solid #003076;
}

.nav-item:hover > .active {
    border-bottom: 2px solid #003076;
}

.nav-items a {
    padding-bottom: 2px;
    text-decoration: none;
}

.resume {
    text-align: right;
    margin: 2vh 1vw 2vh 4vw;
    padding: 0.25% 2vw;
    color: white;
    background-color: #37A7FF;
    border: 2px solid #37A7FF;
    border-radius: 32px;
    text-decoration: none !important;
}

.resume:hover {
    background-color: white;
    color: #37A7FF;
}

/*  GO FULL WIDTH AT LESS THAN 800 PIXELS */
@media only screen and (max-width: 850px) {
    .header {
        position: fixed;
    }

    .nav-items {
        display: none;
    }

    .menu-icon {
        display: inline-block !important;
    }

    .resume {
        display: none;
    }

    .scroll {
        box-shadow: 0 10px 2px 0 grey;
        -webkit-box-shadow: 0 2px 4px 0 grey;
        -moz-box-shadow: 0 2px 4px 0 grey;
    }
}

.logo {
    margin: 1.7vh 2vw 1.7vh 2vw;
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    text-align: left;
    color: #37A7FF;
    float: left;
}

.menu-icon {
    display: none;
    float: right;
    margin: 2vh 2vh 2vh auto;
    padding: 0.5vh;
}

.menu-icon-bar {
    width: 25px;
    height: 4px;
    background-color: #37A7FF;
    margin: 5px 0;
}

.menu-icon-bar:first-child {
    margin-top: 0;
}

.menu-icon-bar:last-child {
    margin-bottom: 0;
}

.menu-icon:hover > .menu-icon-bar {
    background: white;
}

.menu-icon:hover {
    background-color: #37A7FF;
    border-radius: 5px;
    overflow: hidden;
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #37A7FF;
    overflow-x: hidden;
    transition: 0.5s;
}

.overlay-content {
    position: relative;
    top: 22%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.overlay a {
    padding: 10px;
    text-decoration: none;
    font-size: 36px;
    color: white;
    display: block;
    transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
    color: #003076;
}

.overlay .close {
    position: absolute;
    top: 10px;
    right: 35px;
    font-size: 50px;
}
