.home {
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #37A7FF 0%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
}

.text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 15vh;
}

.hi-text {
    color: #003076;
    font-size: 16vw;
    margin: 5% auto 0  auto;
}

.name-text {
    color: #37A7FF;
    font-size: 6vw;
    margin: 2% auto;
}

i {
    display: flex;
    justify-self: flex-end;
    border: solid #003076;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 1.5vh;
    top: 90%;
    position: absolute;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

i:hover {
    border-color: white;
}

@media only screen and (max-width: 1024px) {
    .text-div{
        padding-top: 25vh;
    }
}


